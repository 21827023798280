/* ------------------------------------------------------------ Boat ------------------------------ */
@keyframes Boat {
  from {
    opacity: 0;
    margin-top: 10rem;
  }
  to {
    opacity: 1;
    margin-top: 5rem;
  }
}
@keyframes Boat0 {
  from {
    opacity: 0;
    margin-top: 25rem;
    position: fixed;
  }
  to {
    opacity: 1;
    margin-top: 15rem;
    position: absolute;
  }
}
@keyframes Boat768 {
  from {
    opacity: 0;
    margin-top: 14rem;
  }
  to {
    opacity: 1;
    margin-top: 7rem;
  }
}
/* ------------------------------------------------------- IntroHome ------------------------------ */
@keyframes IntroHome {
  from {
    opacity: 0;
    margin-left: calc(50% - 650px);
  }
  to {
    opacity: 1;
    margin-left: calc(50% - 450px);
  }
}
@keyframes IntroHome768 {
  from {
    opacity: 0;
    margin-left: calc(50% - 550px);
  }
  to {
    opacity: 1;
    margin-left: calc(50% - 350px);
  }
}
@keyframes IntroHome560 {
  from {
    opacity: 0;
    margin-left: calc(-50%);
  }
  to {
    opacity: 1;
    margin-left: calc(50% - 200px);
  }
}
@keyframes IntroHome0 {
  from {
    opacity: 0;
    margin-left: calc(-50%);
  }
  to {
    opacity: 1;
    margin-left: calc(50% - 40%);
  }
}
/* ------------------------------------------------------- SocialMedia ------------------------------ */
@keyframes SocialMedia0 {
  from {
    opacity: 0;
    margin-top: 50rem;
  }
  to {
    opacity: 1;
    margin-top: 10rem;
  }
}

/* ----------------------------------------------- Contact -------------------- */
.Contact {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Poppins;
  font-size: 0.8rem;
  color: #3f4450;
  margin-top: 6rem;
}
/* ----------------------------------------------- Contact 1  -------------------- */
/* BOX Center Say HEllo */
.Contact > :nth-child(1) {
  width: 400px;
  height: 160px;
  margin-top: calc(50vh - 165px);
  margin-left: calc(50% - 200px);
}
/* Just Say Hello! */
.Contact > :nth-child(1) > :nth-child(3) {
  position: relative;
  font-family: Philosopher;
  font-size: 2.2rem;
  top: 10px;
}
/* Hire Me */
.Contact > :nth-child(1) > :nth-child(5) {
  background-color: #3f4450;
  text-transform: uppercase;
  font-size: 0.8rem;
  width: 100px;
  height: 40px;
  margin-top: 2rem;
}
/* Hire Me */
.Contact > :nth-child(1) > :nth-child(5) > :nth-child(2) {
  margin-left: 5px;
}
/* Line Yellow */
.Contact > :nth-child(1) > :nth-child(6) {
  background-color: rgb(254, 223, 45);
  width: 50%;
  height: 10px;
  margin-top: -4.5rem;
  margin-left: calc(50% - 30%);
}
/* Line Circle */
.Contact > :nth-child(1) > :nth-child(7) {
  background-color: rgb(254, 223, 45, 0.2);
  width: 50px;
  height: 50px;
  margin-top: -20%;
  margin-left: 70%;
  border-radius: 50%;
}
/* ----------------------------------------------- Contact 2  -------------------- */
/* Box Contact 2 */
.Contact > :nth-child(2) {
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}
/* img */
.Contact > :nth-child(2) > img {
  width: 500px;
  height: 250px;
}
/* ----------------------------------------------- ContactMiddle  -------------------- */
/* ContactMiddle */
.ContactMiddle {
  width: 700px;
  height: auto;
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: calc(50% - 350px);
}
/* ContactMiddle Left */
.ContactMiddle > :nth-child(1) > :nth-child(1) {
  width: 300px;
  text-align: left;
}
/* ContactMiddle Center */
.ContactMiddle > :nth-child(2) {
  width: 100px;
  height: auto;
  text-align: center;
}
.ContactMiddle > :nth-child(2) > img {
  margin-top: 5rem;
}
/* ContactMiddle Right */
.ContactMiddle > :nth-child(3) {
  width: 300px;
  height: auto;
  text-align: center;
}
/* ContactMiddle Right Button */
.Email-Countainer > :nth-child(2),
.Email-Countainer > :nth-child(3) {
  margin-top: 1rem;
}
/* Hire Me */
.Email-Countainer > :nth-child(5) {
  text-transform: uppercase;
  font-size: 0.8rem;
  width: 120px;
  height: 40px;
  margin-top: 2rem;
}
/* ----------------------------------------------- FORMS  -------------------- */
input {
  width: 80%;
  height: 30px;
  border: none;
  border-bottom: 1px solid rgb(63, 68, 80, 0.5);
  background-color: #ebece9;
  outline: 0;
  color: #3f4450;
}
textarea {
  width: 80%;
  height: 100px;
  border: none;
  border-bottom: 1px solid rgb(63, 68, 80, 0.5);
  background-color: #ebece9;
  outline: 0;
  font-family: Poppins;
  font-size: 0.8rem;
  color: #3f4450;
  resize: vertical;
  margin-top: 1rem;
}
/* ----------------------------------------------- ContactFooter  -------------------- */
.ContactFooter {
  width: 100%;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.7rem;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0000 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (max-width: 559px) {
  /* BOX Center Say HEllo */
  .Contact > :nth-child(1) {
    width: 80%;
    margin-top: 10rem;
    margin-left: calc(50% - 40%);
  }
  /* BOX Center Say HEllo */
  .Contact > :nth-child(1) {
    height: 200px;
  }
  /* img */
  .Contact > :nth-child(2) > img {
    width: 250px;
    height: 125px;
  }
  /* ContactMiddle */
  .ContactMiddle {
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: calc(50% - 35%);
  }
  /* ContactMiddle Left */
  .ContactMiddle > :nth-child(1) > :nth-child(1) {
    width: 100%;
    text-align: left;
  }
  /* ContactMiddle Center */
  .ContactMiddle > :nth-child(2) {
    opacity: 0;
  }
  .ContactMiddle > :nth-child(2) > img {
    margin-top: 0rem;
  }
  /* ContactMiddle Right */
  .ContactMiddle > :nth-child(3) {
    width: 100%;
    height: auto;
    text-align: center;
  }
  /* ----------------------------------------------- FORMS  -------------------- */
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
  /* ----------------------------------------------- ContactFooter  -------------------- */
  .ContactFooter {
    width: 80%;
    margin-left: 10%;
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0560 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 560px) and (max-width: 767px) {
  /* BOX Center Say HEllo */
  .Contact > :nth-child(1) {
    height: 200px;
  }
  /* img */
  .Contact > :nth-child(2) > img {
    width: 400px;
    height: 200px;
  }
  /* ContactMiddle */
  .ContactMiddle {
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: calc(50% - 35%);
  }
  /* ContactMiddle Left */
  .ContactMiddle > :nth-child(1) > :nth-child(1) {
    width: 100%;
    text-align: left;
  }
  /* ContactMiddle Center */
  .ContactMiddle > :nth-child(2) {
    opacity: 0;
  }
  .ContactMiddle > :nth-child(2) > img {
    margin-top: 0rem;
  }
  /* ContactMiddle Right */
  .ContactMiddle > :nth-child(3) {
    width: 100%;
    height: auto;
    text-align: center;
  }
  /* ----------------------------------------------- FORMS  -------------------- */
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0768 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 768px) and (max-width: 989px) {
  /* img */
  .Contact > :nth-child(2) > img {
    width: 400px;
    height: 200px;
  }
}

/* ------------------------------- Animations ---------------------- */
.AUL1,
.AUL2,
.AUL3,
.AUL4,
.AUL5,
.AUL6 {
  display: inline-block;
  position: relative;
}
.AUL1:after,
.AUL2:after,
.AUL3:after,
.AUL4:after,
.AUL5:after,
.AUL6:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  top: 20px;
  background-color: var(--red);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.AUL1:hover::after,
.AUL2:hover::after,
.AUL3:hover::after,
.AUL4:hover::after,
.AUL5:hover::after,
.AUL6:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

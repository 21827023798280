.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* make variables */
:root {
  --yellows: rgb(254, 164, 0);
  --gray: rgb(156, 156, 156);
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: rgb(70, 70, 70);
  --red: rgb(255, 1, 62);
  --button: #3f4450;
  --success: #2e7d32;
  --secondary: #9c27b0;
  --pinks: #c7134f;
  --green: #00a68d;
  --warning: #ed6c02;
}

.LinearProgress {
  text-align-last: left;
  display: flex;
  flex-direction: column;
}
.LinearProgress > :nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@keyframes SHOW {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Percent {
  position: relative;
}

/* Portfolio */
.Portfolio {
  font-family: Poppins;
  font-size: 0.8rem;
  color: #3f4450;
  top: 6.5rem;
  display: flex;
  flex-direction: row;
  margin-top: 6rem;
}
/* ---------------------------------------------------------- LEFT -------------------- */
/* Left */
.Portfolio > :nth-child(1) {
  width: 50%;
  height: 300px;
}
/* Left Box */
.Portfolio > :nth-child(1) > :nth-child(1) {
  width: 400px;
  height: auto;
  margin-left: calc(100% - 400px);
  text-align: left;
  justify-content: left;
  display: flex;
  flex-direction: column;
}
/* Circle */
.Portfolio > :nth-child(1) > :nth-child(1) > :nth-child(1) {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(253, 249, 0, 0.5);
  position: absolute;
  margin-left: 70px;
  margin-top: -17px;
  z-index: 1000;
}
/* my personal */
.Portfolio > :nth-child(1) > :nth-child(1) > :nth-child(2) {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  font-family: Philosopher;
  z-index: 1500;
}
/* portfolio */
.Portfolio > :nth-child(1) > :nth-child(1) > :nth-child(3) {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 1.3rem;
  font-weight: bold;
  font-family: Philosopher;
  z-index: 1500;
}
/* Osho */
.Portfolio > :nth-child(1) > :nth-child(1) > :nth-child(4) {
  margin-top: 2rem;
  text-align: justify;
}
/* Human ideas */
.Portfolio > :nth-child(1) > :nth-child(1) > :nth-child(5) {
  margin-top: 1em;
}
/*  I wrote many */
.Portfolio > :nth-child(1) > :nth-child(1) > :nth-child(6) {
  margin-top: 1em;
}
/* Show More */
.Portfolio > :nth-child(1) > :nth-child(1) > :nth-child(7) {
  background-color: var(--red);
  font-size: 0.8rem;
  width: 140px;
  height: 40px;
  margin-top: 2rem;
}
/* ---------------------------------------------------------- RIGHT -------------------- */
/* Right */
.Portfolio > :nth-child(2) {
  width: 50%;
  height: 300px;
}
/* Dandelion */
.Portfolio > :nth-child(2) > img {
  width: 350px;
  height: auto;
  margin-left: 4rem;
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (max-width: 450px) {
  /* Portfolio */
  .Portfolio {
    flex-direction: column-reverse;
    margin-top: 8rem;
  }
  .Portfolio > :nth-child(1),
  .Portfolio > :nth-child(2) {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  /* Left Box */
  .Portfolio > :nth-child(1) > :nth-child(1) {
    width: 80%;
    height: auto;
    margin-left: calc(50% - 40%);
    text-align: left;
    justify-content: left;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
  /* Dandelion */
  .Portfolio > :nth-child(2) > img {
    width: 250px;
    height: auto;
    margin-left: 0rem;
  }
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (min-width: 451px) and (max-width: 989px) {
  /* Portfolio */
  .Portfolio {
    flex-direction: column-reverse;
    margin-top: 8rem;
  }
  .Portfolio > :nth-child(1),
  .Portfolio > :nth-child(2) {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  /* Left Box */
  .Portfolio > :nth-child(1) > :nth-child(1) {
    width: 80%;
    height: auto;
    margin-left: calc(50% - 40%);
    text-align: left;
    justify-content: left;
    margin-top: 8rem;
    margin-bottom: 4rem;
  }
  /* Dandelion */
  .Portfolio > :nth-child(2) > img {
    width: 400px;
    height: auto;
    margin-left: 0rem;
  }
}

/* --------------------------------------------- IntroHome --------------- */
.IntroHome {
  width: 600px;
  height: auto;
  margin-left: calc(50% - 450px);
  margin-top: 7rem;
  text-align: left;
}
/* Line */
.IntroHome > :nth-child(1) {
  width: 20px;
  height: 1px;
  background-color: #3f4450;
}
/* HELLO */
.IntroHome > :nth-child(2) {
  position: relative;
  margin-left: 30px;
  top: -10px;
}
/* I'm Ramin Maghsoodi */
.IntroHome > :nth-child(3) {
  font-family: Ramabhadra;
  font-size: 3rem;
  color: #3f4450;
  font-weight: bolder;
}
.IntroHome > :nth-child(3) > span {
  color: var(--red);
}
/* Download CV */
.IntroHome > :nth-child(5) {
  font-size: 0.8rem;
  margin-top: 2rem;
  background-color: var(--red);
}
.IntroHome > :nth-child(5):hover {
  background-color: var(--red);
}
/* --------------------------------------------- SocialMedia --------------- */
.SocialMediaHome {
  margin-left: calc(50% - 450px);
  margin-top: 10rem;
}
/* --------------------------------------------- Boat --------------- */
.Boat {
  position: fixed;
  z-index: -500;
  width: 320px;
  height: auto;
  margin-top: 5rem;
  margin-left: 10rem;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0000 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (max-width: 559px) {
  /* --------------------------------------------- IntroHome --------------- */
  .IntroHome {
    width: 80%;
    margin-left: calc(50% - 40%);
    text-align: center;
  }
  /* Line */
  .IntroHome > :nth-child(1) {
    margin-left: calc(50% - 10px);
  }
  /* HELLO */
  .IntroHome > :nth-child(2) {
    margin-left: 0px;
    top: 0px;
  }
  /* --------------------------------------------- SocialMedia --------------- */
  .SocialMediaHome {
    margin-left: calc(50% - 90px);
    margin-bottom: 3rem;
  }
  /* --------------------------------------------- Boat --------------- */
  .Boat {
    position: absolute;
    width: 280px;
    height: auto;
    margin-top: 15rem;
    margin-left: 0rem;
    left: calc(50% - 140px);
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0560 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 560px) and (max-width: 767px) {
  /* --------------------------------------------- IntroHome --------------- */
  .IntroHome {
    width: 400px;
    margin-left: calc(50% - 200px);
    text-align: center;
  }
  /* Line */
  .IntroHome > :nth-child(1) {
    margin-left: calc(50% - 10px);
  }
  /* HELLO */
  .IntroHome > :nth-child(2) {
    margin-left: 0px;
    top: 0px;
  }
  /* --------------------------------------------- SocialMedia --------------- */
  .SocialMediaHome {
    margin-left: calc(50% - 90px);
    margin-bottom: 3rem;
  }
  /* --------------------------------------------- Boat --------------- */
  .Boat {
    position: absolute;
    margin-top: 15rem;
    margin-left: 0rem;
    left: calc(50% - 160px);
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0768 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 768px) and (max-width: 989px) {
  /* --------------------------------------------- IntroHome --------------- */
  .IntroHome {
    margin-left: calc(50% - 350px);
  }
  /* --------------------------------------------- SocialMedia --------------- */
  .SocialMediaHome {
    margin-left: calc(50% - 350px);
    margin-bottom: 3rem;
  }
  /* --------------------------------------------- Boat --------------- */
  .Boat {
    margin-top: 7rem;
    margin-left: 2rem;
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0990 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 990px) and (max-width: 1199px) {
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 1200 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 1200px) {
}

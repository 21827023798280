.Service {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-family: Poppins;
  font-size: 0.8rem;
  color: #3f4450;
}
/* Service Provide */
.Service > :nth-child(2) {
  font-family: Ramabhadra;
  font-size: 1.5rem;
  color: var(--green);
}
/* Box 1 */
.Service > :nth-child(3) {
  width: 100px;
  height: 3px;
  margin-left: calc(50% - 50px);
  background-color: var(--orange);
  margin-top: 0.5rem;
  border-radius: 1px;
}
/* Box 2 */
.Service > :nth-child(4) {
  width: 50px;
  height: 3px;
  margin-left: calc(50% - 25px);
  background-color: var(--orange);
  opacity: 0.4;
  margin-top: 0.5rem;
  border-radius: 1px;
}
/* All Services #1 */
.Service > :nth-child(5) {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  margin-left: calc(50% - 400px);
}
.Service > :nth-child(5) > :nth-child(1),
.Service > :nth-child(5) > :nth-child(2) {
  margin-right: 25px;
}
/* All Services #2 */
.Service > :nth-child(6) {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  margin-left: calc(50% - 400px);
}
.Service > :nth-child(6) > :nth-child(1),
.Service > :nth-child(6) > :nth-child(2) {
  margin-right: 25px;
}
/* ------------------------------------- Setting All Services ------------------- */
/* Services */
.Services {
  text-align: left;
  justify-content: left;
  align-items: left;
  width: 250px;
  height: 212px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.Services:hover {
  background-color: var(--green);
  color: white;
}
.Services > :nth-child(1) {
  width: 30px;
  height: auto;
  padding: 12px;
  background-color: var(--green);
  border-radius: 15px 15px 0px 15px;
  color: white;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 30px;
}
.Services > h3 {
  margin-left: 20px;
  font-family: Ramabhadra;
}
.Services > :nth-child(3) {
  margin-left: 15px;
  margin-top: -7px;
  width: 210px;
  text-align: justify;
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (min-width: 768px) and (max-width: 989px) {
  /* All Services #5 */
  .Service > :nth-child(5) {
    margin-left: calc(50% - 263px);
  }
  .Service > :nth-child(5) > :nth-child(3) {
    position: absolute;
    margin-top: 275px;
  }
  /* All Services #6 */
  .Service > :nth-child(6) {
    margin-left: calc(50% - 263px);
    margin-top: 300px;
  }
  .Service > :nth-child(6) > :nth-child(1) {
    position: absolute;
    margin-top: -275px;
    margin-left: 275px;
  }
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (max-width: 767px) {
  /* All Services #5, #6 */
  .Service > :nth-child(5),
  .Service > :nth-child(6) {
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
  }
  /* Services */
  .Services {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 25px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .Services > :nth-child(3) {
    margin-left: 10%;
    margin-bottom: 20px;
    width: 80%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .Service {
    margin-top: 10rem;
  }
  .Services > :nth-child(1),
  .Services > h3 {
    margin-left: 0px;
  }
}

.Links1 {
  margin-right: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
}
.Links2,
.Links3,
.Links4,
.Links5 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
}
.Links6 {
  margin-left: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (max-width: 559px) {
  .Links {
    position: fixed;
    flex-direction: column;
    background-color: #040b14;
    width: 100%;
    z-index: 5000;
    text-align-last: center;
    display: none;
    opacity: 0;
  }
  @keyframes SHOW2 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .Links1,
  .Links2,
  .Links3,
  .Links4,
  .Links5,
  .Links6 {
    margin-left: 0;
    margin-right: 0;
  }
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (min-width: 560px) and (max-width: 767px) {
  .Links1 {
    margin-right: 0.5rem;
  }
  .Links2,
  .Links3,
  .Links4,
  .Links5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .Links6 {
    margin-left: 0.5rem;
  }
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (min-width: 560px) and (max-width: 767px) {
  .Links1 {
    margin-right: 0.4rem;
  }
  .Links2,
  .Links3,
  .Links4,
  .Links5 {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .Links6 {
    margin-left: 0.4rem;
  }
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (min-width: 768px) and (max-width: 989px) {
  .Links1 {
    margin-right: 0.5rem;
  }
  .Links2,
  .Links3,
  .Links4,
  .Links5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .Links6 {
    margin-left: 0.5rem;
  }
}

.About {
  font-family: Poppins;
  font-size: 0.8rem;
  color: #3f4450;
}
.About > :nth-child(1),
.About > :nth-child(7) {
  justify-content: right;
  text-align: right;
}
.About > :nth-child(2),
.About > :nth-child(8) {
  justify-content: left;
  text-align: left;
}
/* ------------------------------------------------------- SCROLL DOWN ----------------- */
/* ScrollDown */
.ScrollDown {
  text-transform: uppercase;
  transform: rotate(90deg);
  font-size: 0.6rem;
  position: absolute;
  margin-top: 75vh;
}
/* ------------------------------------------------------------- LEFT --------------------- */
/* Deer */
.About > :nth-child(1) > :nth-child(1) {
  width: 350px;
  height: auto;
  margin-right: 2rem;
  margin-top: 5rem;
}
/* ------------------------------------------------------------- RIGHT --------------------- */
/* BOX Right */
.About > :nth-child(2) > :nth-child(1) {
  margin-top: 7rem;
}
/* Line About Me */
.About > :nth-child(2) > :nth-child(1) > :nth-child(1) {
  width: 30px;
  height: 2px;
  background-color: #3f4450;
}
/* Abou Me */
.About > :nth-child(2) > :nth-child(1) > :nth-child(2) {
  position: absolute;
  font-family: Ramabhadra;
  font-size: 1.5rem;
  margin-left: 40px;
  margin-top: -16px;
}
/* I have a curious */
.About > :nth-child(2) > :nth-child(1) > :nth-child(3) {
  width: 400px;
  margin-top: 2rem;
  text-align: justify;
}
/* ------------------------------------------------------------- RIGHT  Buttons --------------------- */
.Buttons {
  display: flex;
}
/* HIRE ME */
.Buttons > :nth-child(1) > :nth-child(1) {
  background-color: var(--red);
  font-size: 0.8rem;
  width: 110px;
  height: 40px;
  margin-top: 3rem;
}
.Buttons > :nth-child(1) > :nth-child(1):hover {
  background-color: var(--red);
}
/* Arrow Button Continue */
.Buttons > :nth-child(2) > :nth-child(1) {
  background-color: var(--button);
  width: 110px;
  height: 40px;
  margin-top: 3rem;
  margin-left: 2rem;
}
.Buttons > :nth-child(2) > :nth-child(1):hover {
  background-color: var(--button);
}
/* ------------------------------------------------------------- PROGRESS --------------------- */
.About > :nth-child(3) > :nth-child(2),
.About > :nth-child(5) > :nth-child(1) {
  width: 50%;
  height: auto;
  margin-left: 47%;
}
.About > :nth-child(4) > :nth-child(1),
.About > :nth-child(6) > :nth-child(1) {
  width: 50%;
  height: auto;
  margin-left: 3%;
}
.About > :nth-child(3) > :nth-child(2) > :nth-child(1),
.About > :nth-child(4) > :nth-child(1) > :nth-child(1),
.About > :nth-child(5) > :nth-child(1) > :nth-child(1),
.About > :nth-child(6) > :nth-child(1) > :nth-child(1) {
  width: 100%;
}
.About > :nth-child(5) > :nth-child(1),
.About > :nth-child(6) > :nth-child(1) {
  margin-top: 1rem;
}
.About > :nth-child(3),
.About > :nth-child(4) {
  margin-top: 10rem;
}
/* ------------------------------------------------------------- My Skills --------------------- */
/* BOX My Skills */
.About > :nth-child(3) > :nth-child(1) {
  position: absolute;
  margin-left: 18%;
  transform: rotate(-90deg);
  display: flex;
  flex-direction: row;
  margin-top: 43px;
}
/* Line My Skills */
.About > :nth-child(3) > :nth-child(1) > :nth-child(1) {
  width: 25px;
  height: 2px;
  background-color: #3f4450;
}
/* My Skills */
.About > :nth-child(3) > :nth-child(1) > :nth-child(2) {
  margin-top: -9px;
  margin-left: 5px;
}
/* ------------------------------------------------------------- Bike --------------------- */
/* Bike */
.About > :nth-child(7) > img {
  width: 400px;
  height: auto;
  margin-right: 2rem;
  margin-top: 5rem;
}
/* Bike About */
.About > :nth-child(8) > :nth-child(1) > :nth-child(1) {
  color: var(--warning);
  margin-top: 5rem;
}
/* Bike Details */
.About > :nth-child(8) > :nth-child(1) > :nth-child(2) {
  font-family: Ramabhadra;
  font-size: 1.5rem;
}
/* Bike Box Line 1 */
.About > :nth-child(8) > :nth-child(1) > :nth-child(3) {
  width: 80px;
  height: 3px;
  background-color: var(--warning);
  border-radius: 3px;
}
/* Bike Box Line 2 */
.About > :nth-child(8) > :nth-child(1) > :nth-child(4) {
  width: 12px;
  height: 3px;
  background-color: var(--warning);
  border-radius: 3px;
  margin-top: -3px;
  margin-left: 85px;
}
/* Bike Box Line 3 */
.About > :nth-child(8) > :nth-child(1) > :nth-child(5) {
  width: 5px;
  height: 3px;
  background-color: var(--warning);
  border-radius: 3px;
  margin-top: -3px;
  margin-left: 102px;
}
/* Bike I have sufficien */
.About > :nth-child(8) > :nth-child(1) > :nth-child(6) {
  width: 60%;
  height: auto;
  margin-top: 2rem;
  text-align: justify;
}
/* Bike All Progress */
.About > :nth-child(8) > :nth-child(1) > :nth-child(7) {
  margin-top: 3rem;
  width: 60%;
  margin-bottom: 5rem;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0000 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (max-width: 300px) {
  .Buttons {
    flex-direction: column;
  }
  /* Arrow Button Continue */
  .Buttons > :nth-child(2) > :nth-child(1) {
    margin-left: 0rem;
  }
}
@media (max-width: 599px) {
  /* Grid Left, Right */
  .About > :nth-child(1),
  .About > :nth-child(2) {
    justify-content: center;
    text-align: center;
  }
  /* Deer */
  .About > :nth-child(1) > :nth-child(1) {
    width: 250px;
    height: auto;
    margin-right: 0rem;
    margin-top: 10rem;
  }
  /* BOX Right */
  .About > :nth-child(2) > :nth-child(1) {
    margin-top: 5rem;
    width: 80%;
    margin-left: 10%;
  }
  /* I have a curious */
  .About > :nth-child(2) > :nth-child(1) > :nth-child(3) {
    width: 100%;
    margin-top: 2rem;
    text-align: justify;
  }
  /* ------------------------------------------------------------- PROGRESS --------------------- */
  /* Progress */
  .About > :nth-child(3) > :nth-child(2),
  .About > :nth-child(5) > :nth-child(1),
  .About > :nth-child(4) > :nth-child(1),
  .About > :nth-child(6) > :nth-child(1) {
    width: 80%;
    height: auto;
    margin-left: 10%;
  }
  .About > :nth-child(5) > :nth-child(1),
  .About > :nth-child(6) > :nth-child(1) {
    margin-top: 1rem;
  }
  .About > :nth-child(3),
  .About > :nth-child(4) {
    margin-top: 1rem;
  }
  .About > :nth-child(3) {
    margin-top: 5rem;
  }
  /* ------------------------------------------------------------- My Skills --------------------- */
  /* BOX My Skills */
  .About > :nth-child(3) > :nth-child(1) {
    margin-left: 4.5%;
    transform: rotate(0deg);
    width: 80%;
    margin-left: 10%;
    text-align: center;
    justify-content: center;
    margin-top: -20px;
  }
  /* ------------------------------------------------------------- Bike --------------------- */
  /*  Bike Grid Progress */
  .About > :nth-child(7) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* Bike Grid Bike */
  .About > :nth-child(8) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* All items Grid 2 */
  .About > :nth-child(8) > :nth-child(1) {
    width: 76%;
    margin-left: 12%;
  }
  /* I have sufficien */
  .About > :nth-child(8) > :nth-child(1) > :nth-child(6) {
    width: 100%;
  }
  /* All Progress */
  .About > :nth-child(8) > :nth-child(1) > :nth-child(7) {
    width: 100%;
  }
  /* Bike */
  .About > :nth-child(7) > img {
    width: 80%;
    margin-top: 0rem;
    margin-left: 8%;
  }
  /* Bike Box Line 1 */
  .About > :nth-child(8) > :nth-child(1) > :nth-child(3) {
    margin-left: calc(50% - 54px);
  }
  /* Bike Box Line 2 */
  .About > :nth-child(8) > :nth-child(1) > :nth-child(4) {
    margin-left: calc(50% - 54px + 85px);
  }
  /* Bike Box Line 3 */
  .About > :nth-child(8) > :nth-child(1) > :nth-child(5) {
    margin-left: calc(50% - 54px + 102px);
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0600 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 600px) and (max-width: 850px) {
  /* Deer */
  .About > :nth-child(1) > :nth-child(1) {
    width: 300px;
    height: auto;
    margin-right: 1.5rem;
    margin-top: 8rem;
  }
  /* BOX Right */
  .About > :nth-child(2) > :nth-child(1) {
    margin-top: 8rem;
  }
  /* About Me */
  .About > :nth-child(2) > :nth-child(1) > :nth-child(3) {
    width: 280px;
  }
  /* I have a curious */
  .About > :nth-child(2) > :nth-child(1) > :nth-child(2) {
    width: 300px;
  }
  /* ------------------------------------------------------------- PROGRESS --------------------- */
  /* Progress */
  .About > :nth-child(3) > :nth-child(2),
  .About > :nth-child(5) > :nth-child(1) {
    width: 70%;
    height: auto;
    margin-left: 27%;
  }
  .About > :nth-child(4) > :nth-child(1),
  .About > :nth-child(6) > :nth-child(1) {
    width: 70%;
    height: auto;
    margin-left: 3%;
  }
  /* ------------------------------------------------------------- My Skills --------------------- */
  /* BOX My Skills */
  .About > :nth-child(3) > :nth-child(1) {
    margin-left: 4.5%;
  }
  /* ------------------------------------------------------------- Bike --------------------- */
  /* Bike I have sufficien */
  .About > :nth-child(8) > :nth-child(1) > :nth-child(6) {
    width: 80%;
  }
  /* Bike All Progress */
  .About > :nth-child(8) > :nth-child(1) > :nth-child(7) {
    width: 80%;
  }
  /* Bike */
  .About > :nth-child(7) > img {
    width: 300px;
    margin-top: 10rem;
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0768 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 768px) and (max-width: 989px) {
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0990 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 990px) and (max-width: 1199px) {
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 1200 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 1200px) {
}

.Header {
  width: 100%;
  height: 33px;
  margin-top: 50px;
}
/* --------------------------------------------------- LOGO --------------------------- */
/* BOX Logo */
.Header-Logo {
  position: absolute;
  width: 200px;
  height: 33px;
  text-align: right;
}
/* Logo */
.Header-Logo > img {
  width: 50px;
  height: 33px;
  cursor: pointer;
}
/* --------------------------------------------------- MENU --------------------------- */
/* Box Menu */
.Header-Menu {
  position: absolute;
  width: 100%;
  height: 33px;
  text-align: center;
}
/* Menus */
.Header-Menu > :nth-child(1) {
  margin-top: 7px;
}
/* --------------------------------------------------- LANG --------------------------- */
/* BOX Lang */
.Header-Lang {
  position: absolute;
  width: 200px;
  height: 33px;
  text-align: left;
  margin-left: calc(100% - 200px);
}
/* Box Text Lang */
.Header-Lang > :nth-child(1) {
  display: inline-flex;
  gap: 0.7rem;
  margin-top: 7px;
}
.Header-Lang > :nth-child(1) > :nth-child(1),
.Header-Lang > :nth-child(1) > :nth-child(2),
.Header-Lang > :nth-child(1) > :nth-child(3) {
  cursor: pointer;
}
/* Language */
.Header-Lang > :nth-child(2) {
  cursor: pointer;
  margin-right: 2rem;
  display: none;
}
.Header-Lang > :nth-child(2) > img {
  width: 23px;
  height: auto;
}
/* Menu */
.Header-Lang > :nth-child(3) {
  position: relative;
  display: none;
  top: -15px;
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0000 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (max-width: 559px) {
  /* BOX Logo */
  .Header-Logo {
    width: 25%;
  }
  /* BOX Lang */
  .Header-Lang {
    width: 25%;
    margin-left: calc(100% - 25%);
  }
  /* Box Text Lang */
  .Header-Lang > :nth-child(1) {
    display: none;
  }
  /* Language */
  .Header-Lang > :nth-child(2) {
    display: none;
  }
  /* Menu */
  .Header-Lang > :nth-child(3) {
    display: inline-flex;
    margin-top: 7px;
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0560 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 560px) and (max-width: 767px) {
  /* BOX Logo */
  .Header-Logo {
    width: 80px;
  }
  /* BOX Lang */
  .Header-Lang {
    width: 80px;
    margin-left: calc(100% - 80px);
  }
  /* Box Text Lang */
  .Header-Lang > :nth-child(1) {
    display: none;
  }
  /* Language */
  .Header-Lang > :nth-child(2) {
    display: inline-flex;
    margin-top: -7px;
  }
}
/* HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH 0768 HHHHHHHHHHHHHHHHHHHHHHHHHHHH */
@media (min-width: 768px) and (max-width: 989px) {
  /* BOX Logo */
  .Header-Logo {
    width: 150px;
  }
  /* BOX Lang */
  .Header-Lang {
    width: 150px;
    margin-left: calc(100% - 150px);
  }
}

/* ------------------------------------------------ Modals -------------------------- */
.Modals {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5000;
  margin-top: -200%;
}
.Modals > :nth-child(1) {
  width: 100%;
  height: 250px;
  background-color: #040b14;
  box-shadow: 0em 0em 1em #040b14;
}
.Modals > :nth-child(2) {
  width: 100%;
  height: calc(100% - 250px);
}
@keyframes SHOW {
  from {
    margin-top: -200%;
  }

  to {
    margin-top: -50px;
  }
}
@keyframes HIDE {
  from {
    margin-top: -50px;
  }

  to {
    margin-top: -200%;
  }
}

.Resume {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-family: Poppins;
  font-size: 0.8rem;
  color: #3f4450;
  margin-bottom: 5rem;
  position: relative;
}
/* Experiance */
.Resume > :nth-child(1) {
  color: var(--warning);
}
/* Work Experiance */
.Resume > :nth-child(2) {
  font-family: Ramabhadra;
  font-size: 1.5rem;
}
/* ------------------------------------------------------------ LINE BOX -------------- */
/* Box Line 1 */
.Resume > :nth-child(3) {
  width: 40px;
  height: 3px;
  margin-left: calc(50% - 34px);
  background-color: var(--warning);
  border-radius: 3px;
  margin-top: 5px;
}
/* Box Line 2 */
.Resume > :nth-child(3) > :nth-child(1) {
  width: 12px;
  height: 3px;
  background-color: var(--warning);
  border-radius: 3px;
  margin-left: 45px;
}
/* Box Line 3 */
.Resume > :nth-child(3) > :nth-child(2) {
  width: 5px;
  height: 3px;
  background-color: var(--warning);
  border-radius: 3px;
  margin-top: -3px;
  margin-left: 62px;
}
/* ------------------------------------------------------------ VERTICAL -------------- */
.Vertical {
  width: 80%;
  height: auto;
  margin-left: 10%;
  margin-top: 3rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.Vertical > :nth-child(1) {
  width: 2px;
  height: auto;
  border-radius: 3px;
  background-color: var(--warning);
  margin-left: calc(50% - 2px);
  padding-top: 5rem;
  padding-bottom: 2rem;
}
/* ------------------------------------------------------------ WorkExperiance -------------- */
.WorkExperiance {
  display: flex;
  width: 802px;
  height: auto;
  /*background-color: rgb(63, 68, 80, 0.1);*/
  margin-left: -400px;
  margin-bottom: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}
/* ------------------------------------------------------------ LEFT ------------------------------- */
.Left {
  width: 350px;
}
.WorkExperianceLeft {
  text-align: justify;
  padding: 9px;
  border: 1px solid rgb(100, 100, 100, 0.1);
  color: #3f4450;
  border-radius: 5px;
}
/* ------------------------------------------------------------ CENTER ------------------------------- */
.Center {
  width: 102px;
}
.Center > :nth-child(1) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--warning);
  background-color: #ebece9;
  margin-left: -18px;
  font-family: Ramabhadra;
  font-size: 1rem;
  cursor: pointer;
  margin-left: calc(50% - 22px);
  color: var(--warning);
  transition: all 0.3s ease-in-out;
}
.Center > :nth-child(1):hover {
  border: 2px solid var(--green);
  color: white;
  background-color: var(--green);
}
/* ------------------------------------------------------------ RIGHT ------------------------------- */
.Right {
  width: 350px;
}
.WorkExperianceRight {
  display: flex;
  flex-direction: column;
  text-align: left;
}
/* ------------------------------------------------------------ TOTAL CSS -------------- */
em {
  position: relative;
  top: 8px;
  font-style: normal;
}
strong {
  color: black;
  font-family: Ramabhadra;
  font-size: 1rem;
}
b {
  color: var(--warning);
  font-family: Poppins;
  font-size: 0.8rem;
}
label {
  color: #3f4450;
  opacity: 0.8;
  font-size: 0.8rem;
}
/* ------------------------------------------------------------ Left To Right -------------- */
.WER2,
.WER4,
.WER6 {
  text-align-last: right;
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (max-width: 767px) {
  .Resume {
    margin-top: 10rem;
  }
  .WorkExperiance {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
  }
  .WorkExperianceRight,
  .WorkExperianceLeft {
    text-align-last: center;
  }
  .Right,
  .Left {
    background-color: #ebece9;
  }
  .WE1,
  .WE3,
  .WE5 {
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .WE2,
  .WE4,
  .WE6 {
    gap: 1rem;
  }
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (max-width: 559px) {
  .WorkExperianceLeft,
  .WorkExperianceRight {
    width: 220px;
    margin-left: 65px;
  }
}
/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (min-width: 768px) and (max-width: 989px) {
  .WorkExperiance {
    width: 702px;
    margin-left: -350px;
  }
  .Left {
    width: 300px;
  }
  .Right {
    width: 300px;
  }
}
